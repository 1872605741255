// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$text-color:            #7d7c7c;
$brand-primary:         #187c91;
$brand-secondary:       #4cc6da;
$brand-gradient:        linear-gradient(45deg, rgba(24, 124, 145, 1) 0%, rgba(76, 198, 218, 1) 100%);
$brand-white:           #fff;
$brand-black:           #000;


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Fonts
$font-family-sans-serif:      "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family:        "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight:        700;
$headings-color:              $brand-primary;
$font-size-base:              16px;

// Border
$border-radius-base: 0;
