// off canvas
#offcanvas {
	position: absolute;
	z-index: 101;
	width: 270px;
	background: $brand-gradient;
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 80px 40px 40px 40px;
	overflow-y: auto;
    transform: translateX(270px);
    transition: all .5s ease;

	.offcanvas & {
      transform: translateX(0);
	}

	ul {
		padding: 0;
		margin: 0;
        position: relative;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
            margin-bottom: 8px;

            > a {
              color: $brand-white;

              &:hover,
              &:focus {
                color: rgba($brand-white,0.6);
              }
            }

			> ul {
				padding-left: 20px;
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						font-family: "Ionicons";
						content: "\f123";
						font-size: 20px;
						color: rgba(255,255,255,.2);
                        transition: all .5s ease;
					}
				}
				&.active {
					a {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}

  .icon-nav {
    border-top: 2px solid rgba($brand-secondary,.8);
    border-bottom: 2px solid rgba($brand-secondary,.8);
    padding: 15px 0;
    margin: 30px 0;
  }
}
