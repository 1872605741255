#page {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: all .5s ease;
  .offcanvas & {
    &:after {
      transition: all 2s ease;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      background: rgba(0, 0, 0, .7);
      content: "";
    }
  }
}
#hero,
.page-header {
  position: relative;
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  color: $brand-white;
  min-height: 500px;
  width: 100%;
  margin-top: 0;
  border: 0 none;

  &:before {
    right: 50%;
    border-right: 2000px solid transparent;
    border-left: 2000px solid;
  }
  &:after {
    left: 50%;
    border-left: 2000px solid transparent;
    border-right: 2000px solid;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 2;
    /*  change the hieght and the color below */
    border-bottom: 400px solid $brand-white;
    transform: rotate(0.000001deg);
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba($brand-primary, .7);
  }
  .header {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-height: 1200px;
    text-align: center;
    color: $brand-white;
    transform: translate(-50%, -50%);
    z-index: 5;

    h1 {
      color: $brand-white;
      font-size: 72px;
      text-transform: uppercase;
      letter-spacing: -2px;
    }
  }
}
#hero,
.page-header {

  &.big {
    min-height: 700px;
    @media(min-width: $screen-sm) {
      min-height: 900px;
      }
  }

  .header {
    padding-top: 50px;

    .label {
      display: inline-block;
      font-family: $headings-font-family;
      font-size: 18px;
      text-transform: uppercase;
      border-radius: 0;
      margin-bottom: 15px;
      @media(min-width: $screen-sm) {
        font-size: 36px;
      }
    }
    .cruise-date {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -2px;
      @media(min-width: $screen-sm) {
        font-size: 30px;
      }
    }
    h1.cruise-title {
      text-transform: uppercase;
      font-size: 40px;
      @media(min-width: $screen-sm) {
        font-size: 72px;
      }
    }
    .cruise-destination {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @media(min-width: $screen-sm) {
        font-size: 24px;
      }
    }
    .cruise-dates {
      display: inline-block;
      padding: 10px 0;
      border: 1px solid $brand-white;
      border-width: 1px 0 1px
    }
    .cruise-price {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -2px;
      @media(min-width: $screen-sm) {
        font-size: 54px;
      }
    }
    .btn-outline {
      padding: 10px;
      font-family: $headings-font-family;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -1px;
      text-transform: uppercase;
      transition: all .3s ease;
      &:hover,
      &:focus {
        background: $brand-white!important;
        border: 1px solid $brand-white!important;
        color: $brand-primary!important;
      }

      @media(min-width: $screen-sm) {
        padding: 15px;
        font-size: 24px;
      }

    }
    .cruise-progress {
      text-align: center;
      .cruise-progress-inner {
        display: inline-block;
        width: 100%;
        max-width: 300px;

        @media(min-width: $screen-sm) {
          max-width: 500px;
        }
      }
    }
    .progress {
      position: relative;
      background: rgba($brand-primary, 0.6);
      box-shadow: none;
      height: 30px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, .2);


      .progress-places {
        position: absolute;
        width:100%;
        height: 30px;
        text-align: center;
        font-size: 18px;
        letter-spacing: -1px;
        text-transform: uppercase;
        line-height: 30px;
      }
      .progress-bar {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(45deg, rgba(24, 124, 145, 1) 0%, rgba(76, 198, 218, 1) 100%);
        line-height: 30px;
      }
    }
  }
}



main {

  .section {
      position: relative;
      padding: 2em 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;

      @media screen and (min-width: $screen-sm) {
          padding: 3em 0;
      }

      h2 {
        font-size: 36px;
        text-align: center;
        margin-bottom: 30px;

        @media(min-width: $screen-sm) {
          font-size: 54px;
        }
      }

      .heading {
        margin-bottom: 30px;
      }

      .overlay {
          z-index: 0;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, .7);
      }

  }

}

#cruises {
	.cruise {
		margin-bottom: 30px;
		> a {
			display: block;
			color: $brand-black;
			position: relative;
			bottom: 0;
			overflow: hidden;
            transition: all .5s ease;

           img {
				position: relative;
                transition: all .5s ease;
			}
			&:after {
				opacity: 0;
				visibility: hidden;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				box-shadow: inset 0px -34px 98px 8px rgba(0,0,0,0.75);
				z-index: 8;
                transition: all .5s ease;
			}
			h3, span {
				z-index: 12;
				position: absolute;
				left: 20px;
                right: 20px;
				color: $brand-white;
				margin: 0;
				padding: 0;
				opacity: 1;
				visibility: visible;
                transition: all .3s ease;
			}
            h3 {
                font-size: 24px;
				font-weight: 700;
                bottom: 60px;
            }
			.cruise-location{
 				font-size: 14px;
 				bottom: 20px;
 			}
            .cruise-price{
 				font-size: 24px;
                font-weight: 700;
                text-align: right;
 				top: 40px;
 			}
            .cruise-date{
 		  		font-size: 16px;
                font-weight: 700;
                text-align: right;
                text-transform: uppercase;
 				top: 20px;
 			}
			&:hover {
				box-shadow: 0px 18px 71px -10px rgba(0,0,0,0.75);

				&:after {
					opacity: 1;
					visibility: visible;
					@media screen and (max-width: $screen-sm ) {
						opacity: 0;
						visibility: hidden;
					}
				}
				h3,.cruise-location, .cruise-price, .cruise-date {
					opacity: 1;
				}
                h3 {
                  bottom: 70px;
                }
				.cruise-location {
					bottom: 25px;
				}
              .cruise-date {
                top: 25px;
              }
              .cruise-price {
                top: 45px;
              }
				img {
					transform: scale(1.1);

					@media screen and (max-width: $screen-sm ) {
						transform: scale(1.0);
					}
				}
			}
		}
	}
}

#cruise-tabs {
  margin: 30px 0;
  text-align: center;
  .nav-tabs {
    font-size: 24px;
    font-family: $headings-font-family;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1px;
    display: inline-block;
    color: #c8c8c8;
    padding: 15px 20px;
    border: 0 none;

    @media(min-width: $screen-sm-min) {
      font-size: 40px;
    }

    >li>a {
      color: #c8c8c8;
      border: 0 none;
      background: transparent;

    }
    >li>a:hover,
    >li>a:focus,
    >li.active>a,
    >li.active>a:focus,
    >li.active>a:hover {
      color: $brand-primary;
      border: 0 none;
      background: transparent;
    }
  }

}


.entry-aside {
  margin-bottom: 60px;
}
.flexslider {
  margin: 0;
}

.flex-direction-nav a:before {
    font-family: 'Ionicons'!important;
    font-size: 34px;
    line-height: 34px;
    content: "\f124";
}
.flex-direction-nav a.flex-next:before {
  content: "\f125";
}


.masonry-column {
  margin-bottom: 30px;
}


#newsletter {
  text-align: center;
  background: $brand-gradient;
  color: $brand-white;

  h3 {
    color: $brand-white;
  }

  .form-control {
    border: 2px solid $brand-white;
     color: $brand-white;

    &::placeholder {
      color: rgba($brand-white,.5);
    }
  }
}

// User dashboard
.cruise-gallery {
  margin-bottom: 60px;
}

[id^=participants-guests-] .modal-dialog,
#cruise-booking {
  max-width: none;
  width: 80%;
}
