// Nav
header.nav {
	position: absolute;
	margin: 15px 0;
	padding: 0;
    top: 0;
    left:0;
	width: 100%;
	z-index: 99;
    font-family: $headings-font-family;

	.brand {
      text-align: center;

      a {
		margin: 0;
		padding: 0;
		display: inline-block;
		text-indent: -9999px;
        width: 100px;
        height: 100px;
        background-image: url(../images/golden-dolphin-logo.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px;
		font-size: 36px;
		font-weight: 700;
		padding-left: 0;

        @media(min-width: $screen-sm) {
          width: 190px;
          height: 190px;
          background-size: 190px;
        }

//        .svg & {
//          background-image: url(../images/golden-dolphin-logo.svg);
//        }

      }
    }

    .menu {
      display: inline-block;
    }

    .icon-nav {
        display: inline-block;

        a {
          border: 1px solid $brand-white;
        }
    }

    .open>a,
    .open>a:focus,
    .open>a:hover {
        background-color: transparent!important;
        border: 0 none!important;
    }


	.primary-nav,
    .left-nav,
    .right-nav {
        position: relative;
        margin-top: 65px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: $brand-white;

        @media(min-width:$screen-lg) {
          font-size: 18px;
        }

		@media screen and (max-width: $screen-md ) {
			display: none;
		}
	}

    .right-nav {
      text-align: right;
    }

	ul {
		padding: 0;
		margin: 2px 0 0 0;

        @media (max-width: $screen-md) {
          display: inline-block !important;
        }

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;

			a {
                color: $brand-white;
                padding: 10px 15px;
                transition: all .5s ease;
                &:hover,
                &:focus {
                  background: transparent;
				}
			}

            .dropdown-menu {
					position: absolute;
					width: 150px;
					box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.2);
					z-index: 1002;
				/*	visibility: hidden;
					opacity: 0;*/
					top: 40px;
					left: -100px;
					text-align: left;
					background: $brand-white;
					padding: 20px;
                    border-radius: 4px;
                    border: 0 none;

                   /* &.animated {
                      animation-duration: .5s;
                    }*/

					&:before {
						bottom: 100%;
						// left: 30%;
						right: 20px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-bottom-color: #fff;
						border-width: 8px;
						margin-left: -8px;
					}

					li {
						display: block;
						margin-bottom: 7px;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							padding: 2px 0;
							display: block;
							color: $brand-primary;
							line-height: 1.2;
							text-transform: uppercase;
							font-size: 15px;
							&:hover {
								color: $brand-secondary;
							}
						}
					}
				}


		}
	}
}

// Burger Menu
.nav-toggle {
  position: absolute;
  cursor: pointer;
  text-decoration: none;
  right: 0px;
  top: 44px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 103;
  border-bottom: none!important;

  @media screen and (max-width: $screen-md) {
  	display: block;
  }

  &.active i {
		&::before, &::after {
			background: $brand-white;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  display: inline-block;
	  width: 25px;
	  height: 2px;
	  color: $text-color;
	  font: bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $brand-white;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 25px;
		  height: 2px;
		  background: $brand-white;
		  position: absolute;
		  left:0;
		  transition:all .2s ease-out;
	  }
  }

  i::before {
    top: -7px;
  }
  i::after {
    bottom: -7px;
  }
  &.active i {
      background: transparent;

      &::before {
        top:0;
        transform: rotateZ(45deg);
      }
      &::after {
        bottom:0;
        transform: rotateZ(-45deg);
      }
  }

}


#login-form-container,
#signup-form-container,
#lost-password-form-container {
    text-align: center;
    .form-group,
    .form-control {
        text-align: left;
    }

    .lost-password-link-container {
      margin-top: 20px;
    }
}
