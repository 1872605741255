.content-info {
  position: relative;
  padding: 50px 0;
  text-align: center;
  clear: both;

  .social-icons {
    color: $brand-primary;
    font-size: 20px;
    margin-bottom: 60px;
    a {
      color: $brand-primary;

      &:hover,
      &:focus {
        color: $brand-secondary;
      }

      i {
        font-size: 24px;
      }
    }
  }

  .copyright {
    font-size: 15px;
    letter-spacing: -1px;
    text-transform: uppercase;
  }
}
