html {
  @media screen and (max-width: $screen-sm) {
    margin-top: 0 !important;
  }
}

body {
  position: relative;
  font-weight: 300;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Selection
::-webkit-selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

::-moz-selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

::selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

// Links
a {
  transition: all .3s ease;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}


// Modals
.modal-backdrop {
  background: $brand-gradient;
  &.in {
    opacity: 0.9;
  }
}
.modal-dialog {
  margin-top: 60px;
}
.modal-content {
  padding: 30px;
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 10px 20px rgba(0,0,0,.2)
}

// Buttons
.btn {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -1px;
  padding: 15px;
  border: 0 none;
  transition: all .3 ease;
}
.btn-primary {
  background: $brand-gradient;
  font-family: $headings-font-family;
  color: $brand-white;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
}
.btn-outline {
  background: transparent!important;
  border: 1px solid;
  box-shadow: none;
  border-radius: 0;
}

// Titles
h1,h2, h3, h4, h5 {
  text-transform: uppercase;
}

// Sharer
#sharer {
  display: block;
  text-align: center;
  padding: 30px 0;

  .title {
    display: inline-block;
    color: #ddd;
    margin-right: 10px;
  }
  .buttons {
    display: inline-block;

    a {
      color: #ccc;
      transition: all .3s ease-in-out;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

// labels
.label-secondary {
  background-color: $brand-secondary;
}


// Loader
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: $brand-gradient;
    transition: 1s;
}

.no-js .loader {
	display: none;
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.ball-scale-multiple > div {
    background-color: $brand-white;
}

// Animate
.animate-box {
	.js & {
		opacity: 0;
	}
}

// Social icons
.social-icons {
  margin-bottom: 0;
  color: #fff;
  font-family: $headings-font-family;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;

  .list-title {
    display: block;

    @media(min-width: $screen-md) {
      display: inline-block;
    }
  }

  i {
    font-size: 18px;
  }

  a {
    color: #fff;
    transition: all .3s ease;
    padding: 5px;

    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: none;
    }
  }
}


// 404
#page-not-found {
  position: relative;
  background: $brand-gradient;
  background-size: cover;
  background-position: center;
  color: #fff;
  width: 100%;
  height: 100vh;

  &:after {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $brand-primary;
    opacity: 0.9;
  }

  .header {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 600px;
    padding: 30px;
    text-align: center;
    transform: translate(-50%,-50%);
    background: rgba(#000,.7);
    z-index: 2;

    h1 {
    font-size:  120px;
    font-weight: 900;
    color: #fff;

      span {
        display: block;
        font-size: 36px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
      }

    }

    .message {
      margin-bottom: 30px;
    }

  }
}


// no-gutters
.row {
    &.no-gutters {
    margin-right: 0;
    margin-left: 0;

     > [class^="col-"],
     > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}


// Magnific popup
.mfp-bg {
    background: $brand-gradient;
    opacity: 0.9;
}
.mfp-figure:after{
    box-shadow: 0px 15px 30px rgba(0,0,0,.15);
}
.mfp-arrow {
    i {
        font-size: 72px;
        line-height: 0;
        color: $brand-white;
    }
    &:before,
    &:after {
        display: none;
    }
}
.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}


// navtabs


.nav-tabs,
.nav-pills {
    text-align:center;
    border: 0 none;
    margin-bottom: 30px;

    & > li,
    & > li {
        float:none;
        display:inline-block;
        *display:inline; /* ie7 fix */
         zoom:1; /* hasLayout ie7 trigger */

        a {
            border: 0 none;
            text-transform: uppercase;
            letter-spacing: -1px;
        }
    }
}

.nav-tabs {
    >li.active>a,
    >li.active>a:focus,
    >li.active>a:hover,
    >li>a:hover,
    >li>a:focus {
        border: 0 none;
        color: $brand-primary;
        background: transparent;
    }
    >li>a {
      color: #c8c8c8;
      font-weight: 700;

    }
}
