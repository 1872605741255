.half-left,
.half-right,
.full-width,
.two-columns,
.three-columns,
.four-columns,
.downloads,
.partners,
.pricing-box {
  position: relative;
  overflow: hidden;

  .block {
    margin-bottom: 50px;

    @media(min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  img {
    box-shadow: 0px 10px 30px rgba(0,0,0,0.12);
  }

  p {
    text-align: justify;
  }

  .img-wrap {
    margin-bottom: 30px;

    @media(min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  h2 {
    position: relative;
    text-shadow: none;
    margin-bottom: 30px;
    color: $brand-primary;
    font-weight: 700;
    font-size: 44px;

  }

  &.background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;

    .row {
      position: relative;
      z-index: 2;
    }

    &:after {
      content:'';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(#000,0.9);
      top:0;
      left: 0;
    }
  }

  .btn {
    display: inline-block;
  }

  .more {
    margin-top: 15px;
  }

  &.is-neg {
    h2,h3,h4,h5 {
      color: #fff !important;
    }

    .section-heading {
      color: #fff !important;
    }

    .item {
      color: #fff !important;
    }

    p {
      color: #fff !important;
    }
  }
}

.two-columns,
.three-columns,
.four-columns,
.downloads {

  h2 {
    font-size: 54px;
    font-weight: 300;
    text-align: left;
  }

  .section-heading {
    margin-bottom: 50px;
  }
  .item {
    text-align: left;
    margin-bottom: 30px;

    @media(min-width:$screen-sm-min) {
      margin-bottom: 0;
    }

    .item-inner {
      padding: 30px;


      &.border {
        border: 10px solid #f7f7f7;
        background: $brand-white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px;
      }

    }

    .item-image {
      max-width: 200px;
      display: inline-block;
      margin-bottom: 20px;
    }

    .item-content {
      p {
        margin-bottom: 15px;
      }
    }

    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      color: $brand-primary;
      margin-bottom: 30px;
      margin-top: 0;
    }
  }
}


.downloads {
  text-align: center;

  .section-heading {
    h2 {
      text-align: center;
    }
  }

  ul {
    display: inline-block;

    li {
      margin-bottom: 15px;

       @media(max-width: $screen-sm) {
          display: block; // Display block on mobile
        }

      .btn {
        @media(max-width: $screen-sm) {
          display: block; // Display block on mobile
        }
      }
    }
  }
}

.pricing-box {
  .item {
    text-align: center;
    margin-bottom: 30px;

    @media(min-width: $screen-sm) {
      margin-bottom: 0;
    }

    .item-inner {
      border: 1px solid #ededed;

      .item-title {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #1c1c1c;
        font-weight: 700;
        color: #FFF;
      }
      .item-price {
        padding: 35px;
        background-color: #f7f7f7;

        h4 {
          font-size: 40px;
          line-height: 46px;
          color: #232323;
          font-weight:700;
        }
      }
      .item-list {
        padding: 30px;

        ul {
          li {
            position: relative;
            font-size: 15px;
            padding: 0 0 8px 0;
            margin: 0 0 8px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      .pricing-action {
        margin-top: 35px;

        .btn-primary {
          background: #1c1c1c;
        }
      }
    }

    &.highlight {
      .item-inner {
        transform: scale(1.1);
        box-shadow: 0px 10px 20px rgba(0,0,0,0.12);

        .item-title {
          background: $brand-primary;
        }

        .pricing-action {
          .btn-primary {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
    }

  }
}

.partners {
  .partner {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(0,0,0,.12);

    &:last-child {
      border-bottom: 0 none;
      margin-bottom: 0;
    }

    h2 {
      margin-top: 0;
    }

    img {
      box-shadow: none !important;
    }

  }
}
