
// Bubbles
.bubbles-wrapper {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  .bubbles {
    width: 100%;
    height: auto;
    circle {
      stroke: white;
      fill: none;
    }
    > g > g:nth-of-type(3n) circle {
      stroke: $brand-primary;
    }
    > g > g:nth-of-type(4n) circle {
      stroke: $brand-secondary;
    }
  }
  .bubbles-large {
    overflow: visible;
    > g {
      transform: translateY(2048px);
      opacity: 0;
      will-change: transform, opacity;
    }
    g:nth-of-type(1) {
      animation: up 6.5s infinite;
      g {
        transform: translateX(350px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(2) {
      animation: up 5.25s 250ms infinite;
      g {
        transform: translateX(450px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(3) {
      animation: up 6s 750ms infinite;
      g {
        transform: translateX(700px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(4) {
      animation: up 5.5s 1.5s infinite;
      g {
        transform: translateX(500px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(5) {
      animation: up 6.5s 4s infinite;
      g {
        transform: translateX(675px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
  }
  .bubbles-small {
    overflow: visible;
    > g {
      transform: translateY(2048px);
      opacity: 0;
      will-change: transform, opacity;
    }
    g circle {
      transform: scale(0);
    }
    g:nth-of-type(1) {
      animation: up 5.25s infinite;
      g {
        transform: translateX(350px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(2) {
      animation: up 5.75s infinite;
      g {
        transform: translateX(750px);
      }
      circle {
        animation: wobble 3s infinite ease-in-out;
      }
    }
    g:nth-of-type(3) {
      animation: up 5.25s 250ms infinite;
      g {
        transform: translateX(350px);
      }
      circle {
        animation: wobble 3s 250ms infinite ease-in-out;
      }
    }
    g:nth-of-type(4) {
      animation: up 5.75s 325ms infinite;
      g {
        transform: translateX(180px);
      }
      circle {
        animation: wobble 3s 325ms infinite ease-in-out;
      }
    }
    g:nth-of-type(5) {
      animation: up 6s 125ms infinite;
      g {
        transform: translateX(350px);
      }
      circle {
        animation: wobble 3s 250ms infinite ease-in-out;
      }
    }
    g:nth-of-type(6) {
      animation: up 5.13s 250ms infinite;
      g {
        transform: translateX(650px);
      }
      circle {
        animation: wobble 3s 125ms infinite ease-in-out;
      }
    }
    g:nth-of-type(7) {
      animation: up 6.25s 350ms infinite;
      g {
        transform: translateX(480px);
      }
      circle {
        animation: wobble 3s 325ms infinite ease-in-out;
      }
    }
    g:nth-of-type(8) {
      animation: up 7s 200ms infinite;
      g {
        transform: translateX(330px);
      }
      circle {
        animation: wobble 3s 325ms infinite ease-in-out;
      }
    }
    g:nth-of-type(9) {
      animation: up 6.25s 233ms infinite;
      g {
        transform: translateX(230px);
      }
      circle {
        animation: wobble 3s 275ms infinite ease-in-out;
      }
    }
    g:nth-of-type(10) {
      animation: up 6s 900ms infinite;
      g {
        transform: translateX(730px);
      }
      circle {
        animation: wobble 2s 905ms infinite ease-in-out;
      }
    }
  }
  @keyframes wobble {
    33% {
      transform: translateX(-50px);
    }
    66% {
      transform: translateX(50px);
    }
  }
}
@keyframes up {
  0% {
    opacity: 0;
  }
  10%,
  70% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
    transform: translateY(-1024px);
  }
}
